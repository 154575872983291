const QUESTIONS_REG_EX = [
  {
    type: 'textarea',
    regex:
      /[\\?] ?\[([0-9a-z_].*?)\](\{.*?\}(?=\[))? ?\[?(.*?)\]? ?[=] ?([_]{2,}) ?(\[.*\])? ?({.*})?/g,
  },
  {
    type: 'text',
    regex:
      /[\\?] ?\[([0-9a-z_].*?)\](\{.*?\}(?=\[))? ?\[?(.*?)\]? ?[=] ?([_]{1}) ?(\[.*\])? ?({.*})?/g,
  },
  {
    type: 'radio',
    regex:
      /[\\?] ?\[([0-9a-z_].*?)\](\{.*?\}(?=\[))? ?\[?(.*?)\]? ?[=] ?\(\) ?({[0-9,]+})?([^]*?)(\n\n|^\n)/g,
  },
  {
    type: 'checkbox',
    regex:
      /[\\?] ?\[([0-9a-z_].*?)\](\{.*?\}(?=\[))? ?\[?(.*?)\]? ?[=] ?\[\] ?({[0-9,]+})?([^]*?)(\n\n|^\n)/g,
  },
  {
    type: 'graph',
    regex:
      /[\\?] ?\[([0-9a-z_].*?)\](\{.*?\}(?=\[))? ?\[?(.*?)\]? ?[=] ?\|\|([^]*?)(\n\n|^\n)/g,
  },
];

const CONTENT_SECTION_REG_EXS = [
  {
    type: 'instruction',
    // Getting all the text upto the end or the begining of the question tag
    regex: /^(?!(\?)|(\[\])|(\(\))).+(([^]*?)\n\n\?\[|([^]*?)\n\n)/gm,
  },
].concat(QUESTIONS_REG_EX);

const removeFirstAndLastChar = function (text) {
  if (text) {
    text = text.trim();
    return text.substring(1, text.length - 1);
  } else {
    return '';
  }
};

const isQuestionType = function (obj) {
  return obj.type !== 'instruction';
};

const isNextQuestionType = function (markdownObj, index) {
  return (
    index + 1 < markdownObj.length && isQuestionType(markdownObj[index + 1])
  );
};

const showQuestionLabel = function (markdownObj, index) {
  return (
    (index === 0 && isQuestionType(markdownObj[index])) ||
    (index !== 0 &&
      !isQuestionType(markdownObj[index - 1]) &&
      isQuestionType(markdownObj[index]))
  );
};

const getQuestionHeader = function (markdownObj, index) {
  let questionHeader = '';
  if (showQuestionLabel(markdownObj, index)) {
    if (isNextQuestionType(markdownObj, index)) {
      questionHeader = `**Questions:**\n`;
    } else {
      questionHeader = `**Question:**\n`;
    }
  }
  return questionHeader;
};

const QuestionsUtil = {
  // Replacing all questions specific to markdowns to
  // <div>--:<QUESTION_ID>:--</div>
  // and extract the questions objects
  // to process it later with react process node library
  // to provide react features to all dynamic input components
  extractQuestions: function (text) {
    const questions = {};
    const questionsMeta = {};
    if (text) {
      const _questions = {};
      // DOS to Unix
      text = text.replace(/\r\n/g, '\n');
      // Mac to Unix
      text = text.replace(/\r/g, '\n');
      // Make sure text begins and ends with a couple of newlines:
      text = '\n\n' + text + '\n\n';
      QUESTIONS_REG_EX.forEach(regEx => {
        text = text.replace(regEx.regex, function (match, ...group) {
          const attrs = group[1];

          var question = {};
          if (attrs) {
            try {
              question = JSON.parse(attrs);
            } catch (err) {
              // Ignore the exception
            }
          }
          question.questionId = group[0];
          question.question = group[2];
          question.type = regEx.type;

          if (question.type === 'checkbox' || question.type === 'radio') {
            const answers = removeFirstAndLastChar(group[3]),
              optionsItems = group[4],
              isCheckbox = question.type === 'checkbox';
            if (answers) {
              question.answer = isCheckbox
                ? answers.split(',').map(function (item) {
                    return parseInt(item, 10);
                  })
                : parseInt(answers, 10);
            }
            question.options = [];

            var optionRegEx = isCheckbox
              ? /^ {0,2}(\[\*?\])[ \t](.*)/gm
              : /^ {0,2}(\(\*?\))[ \t](.*)/gm;
            optionsItems.replace(optionRegEx, function (match, type, text) {
              question.options.push(text);
            });
          } else if (question.type === 'text' || question.type === 'textarea') {
            const answer = removeFirstAndLastChar(group[5]),
              placeHolderText = removeFirstAndLastChar(group[4]);
            question.answer = answer;
            const questionMeta = {
              placeHolder: placeHolderText,
            };
            if (question.type === 'textarea') {
              questionMeta.rows = group[3].length;
            }
            questionsMeta[question.questionId] = questionMeta;
          } else if (question.type === 'graph') {
            // Textbox
            const answer = group[3];
            if (answer && answer.trim()) {
              var _ans = JSON.parse(answer.trim());
              if (Object.keys(_ans).length > 0) {
                question.answer = _ans;
              }
            }
          }
          _questions[question.questionId] = question;
          return '<div>--:' + question.questionId + ':--</div>';
        });
      });

      // To maintain the questions order as per the markdown
      const matches = [...text.matchAll(/--:([a-z0-9_]*):--/g)];
      matches.forEach(match => {
        const questionId = match[1];
        questions[questionId] = _questions[questionId];
      });
    }
    return {
      text,
      questions,
      questionsMeta,
    };
  },
  hasCheckableAns: function (question) {
    // Return true any of the question has checkable attribute
    const checkableQuestions = question.filter(ques => {
      return !!ques.checkable;
    });
    return checkableQuestions?.length > 0;
  },
};

export const MarkdownJSONUtil = {
  // Replacing all questions specific to markdowns to
  // <div>--:<QUESTION_ID>:--</div>
  // and extract the questions objects
  // to process it later with react process node library
  // to provide react features to all dynamic input components
  toJSON: function (text) {
    const contents = [];
    const questionsMeta = {};
    if (text) {
      const _questions = {};
      const _instructions = {};

      // remove Question/Questions label from markdown before conversion
      // as it's only used for display purpose
      text = text.replaceAll(/^\*\*Questions?:\*\*$/gm, '');

      // DOS to Unix
      text = text.replace(/\r\n/g, '\n');
      // Mac to Unix
      text = text.replace(/\r/g, '\n');
      // Make sure text begins and ends with a couple of newlines:
      text = '\n\n' + text.trim() + '\n\n';
      let instructionCount = 0;
      CONTENT_SECTION_REG_EXS.forEach(regEx => {
        text = text.replace(regEx.regex, function (match, ...group) {
          const attrs = group[1];
          var question = {};
          var instruction = {};
          if (attrs) {
            try {
              question = JSON.parse(attrs);
            } catch (err) {
              // Ignore the exception
            }
          }
          question.questionId = group[0];
          question.question = group[2];
          question.type = regEx.type;

          if (question.type === 'checkbox' || question.type === 'radio') {
            const answers = removeFirstAndLastChar(group[3]),
              optionsItems = group[4],
              isCheckbox = question.type === 'checkbox';
            if (answers) {
              question.answer = isCheckbox
                ? answers.split(',').map(function (item) {
                    return parseInt(item, 10);
                  })
                : parseInt(answers);
            }
            question.options = [];

            var optionRegEx = isCheckbox
              ? /^ {0,2}(\[\*?\])[ \t](.*)/gm
              : /^ {0,2}(\(\*?\))[ \t](.*)/gm;
            optionsItems.replace(optionRegEx, function (match, type, text) {
              question.options.push(text);
            });
          } else if (question.type === 'text' || question.type === 'textarea') {
            const answer = removeFirstAndLastChar(group[5]),
              placeHolderText = removeFirstAndLastChar(group[4]);
            question.answer = answer;
            question.placeHolder = placeHolderText;
            const questionMeta = {
              placeHolder: placeHolderText,
            };
            if (question.type === 'textarea') {
              questionMeta.rows = group[3].length;
              question.rows = group[3].length;
              // placeHolderText.rows = group[3].length;
            }
            questionsMeta[question.questionId] = questionMeta;
          } else if (question.type === 'graph') {
            // Textbox
            const answer = group[3];
            if (answer && answer.trim()) {
              var _ans = JSON.parse(answer.trim());
              if (Object.keys(_ans).length > 0) {
                question.answer = _ans;
              }
            }
          } else if (question.type === 'instruction') {
            let isNextQuestion = false;
            // Remove the question mark
            if (match.slice(-3) === '\n?[') {
              isNextQuestion = true;
              match = match.slice(0, -3);
            }

            // Remove trailing new lines
            instruction.text = match.trim();
            let keyName = `instruction_${instructionCount}`;
            instruction.instructionId = keyName;
            instruction.type = regEx.type;
            _instructions[keyName] = instruction;
            return `--:instruction_${instructionCount++}:--${
              isNextQuestion ? '\n?[' : ''
            }`;
          }
          // For only questions
          _questions[question.questionId] = question;
          return '--:' + question.questionId + ':--';
        });
      });

      // To maintain the questions order as per the markdown
      const matches = [...text.matchAll(/--:([a-z0-9_]*):--/g)];
      matches.forEach(match => {
        const questionORinstructionId = match[1];
        if (questionORinstructionId.startsWith('instruction')) {
          const lastContent =
            contents.length > 0 ? contents[contents.length - 1] : undefined;
          // To avoid splitting of single instruction to multiple instruction,
          // append the instruction to previous instruction when the previous content type is instruction
          // TODO: Fix instruction REGEX to avoid this workaround
          if (lastContent?.type === 'instruction') {
            lastContent.text +=
              '\n\n' + _instructions[questionORinstructionId].text;
          } else {
            contents.push(_instructions[questionORinstructionId]);
          }
        } else {
          contents.push(_questions[questionORinstructionId]);
        }
      });
    }
    return contents;
  },
  toMarkdown: function (markdownObj) {
    let str = '';
    let questionHeader;
    markdownObj.forEach((obj, index) => {
      questionHeader = getQuestionHeader(markdownObj, index);
      switch (obj.type) {
        case 'instruction':
          str += `${obj.text}\n`;
          break;
        case 'text':
          str +=
            questionHeader +
            `?[${obj.questionId}]{"category":${JSON.stringify(obj.category)}}[${
              obj.question
            }]=_${obj.placeHolder ? `[${obj.placeHolder}]` : ''}\n`;
          break;
        case 'textarea':
          str +=
            questionHeader +
            `?[${obj.questionId}]{"category":${JSON.stringify(obj.category)}}[${
              obj.question
            }]=${'_'.repeat(obj.rows || 2)}${
              obj.placeHolder ? `[${obj.placeHolder}]` : ''
            }\n`;
          break;
        case 'checkbox':
          str +=
            questionHeader +
            `?[${obj.questionId}]{"category":${JSON.stringify(obj.category)}}[${
              obj.question
            }]=[] ${`{${obj.answer.toString()}}`}\n`;
          for (let i = 0; i < obj.options.length; i++) {
            str += `[] ${obj.options[i]}\n`;
          }
          break;
        case 'radio':
          str +=
            questionHeader +
            `?[${obj.questionId}]{"category":${JSON.stringify(obj.category)}}[${
              obj.question
            }]=() ${`{${obj.answer.toString()}}`}\n`;
          for (let i = 0; i < obj.options.length; i++) {
            str += `() ${obj.options[i]}\n`;
          }
          break;
        case 'graph':
          str += `?[${obj.questionId}]{"category":[]}[${obj.question}]=||{}\n`;
          break;
        default:
          break;
      }
      // Seperate each content with 2 new lines
      if (index < markdownObj.length - 1) {
        str += '\n\n';
      }
    });
    return str;
  },
  getMaxInstructionNumber: function (questionBlocksArr) {
    if (!questionBlocksArr || questionBlocksArr.length === 0) {
      return 0;
    }
    return Math.max(
      ...questionBlocksArr.map(o => {
        if (!o.instructionId) {
          return 0; // to avoid handle instructionID
        }
        return parseInt(o.instructionId.slice(12)); // avoid questionID characters
      }),
    );
  },
  getMaxQuestionNumber: function (questionBlocksArr) {
    if (!questionBlocksArr || questionBlocksArr.length === 0) {
      return 0;
    }
    return Math.max(
      ...questionBlocksArr.map(o => {
        if (!o.questionId) {
          return 0; // to avoid handle instructionID
        }
        return parseInt(o.questionId.slice(9)); // avoid questionID characters
      }),
    );
  },
  getAnswerKey: function (questions, answerKey) {
    let answer_key = {};
    questions.forEach(question => {
      if (question.type === 'radio' || question.type === 'checkbox') {
        answer_key[question.questionId] = {
          solution: question.solution,
        };
      } else if (question.type === 'graph') {
        answer_key[question.questionId] = {
          solution: question.solution,
          preview: question.preview,
          answer: question.answer,
        };
      } else if (question.type !== 'instruction') {
        answer_key[question.questionId] = {
          solution: question.solution,
        };
      }
    });
    return answer_key;
  },
};

export default QuestionsUtil;
