import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PremiumBlockModal } from '../../PremiumBlock/PremiumBlock';
import Util from '../../Util';
import Button from '../../Button/Button';

const showLoginForm = window.showLoginForm;

const CreateActivityButton = ({
  className,
  user,
  access_url,
  is_premium_dataset,
  is_jr_dataset,
  isUserDataset = false,
}) => {
  const [showPremiumBlock, setShowPremiumBlock] = useState(false);
  const isTeacher = user && user.type === 'teacher';
  const hasTeacherSubsctiption = isTeacher && (user.is_premium || user.is_jr);
  const hasAccessToContentBuilder = Util.hasAccessToContentBuilder(
    user,
    is_premium_dataset,
    is_jr_dataset,
  );
  const baseUrl = isUserDataset
    ? `/content-builder/userdataset-activity/${access_url}`
    : `/content-builder/activity/${access_url}`;

  const createActivityClick = function () {
    if (!user) {
      showLoginForm();
      return 0;
    }
    if (
      (isUserDataset && hasTeacherSubsctiption) ||
      (isTeacher && hasAccessToContentBuilder && !isUserDataset)
    ) {
      window.location = `${baseUrl}/new/`;
    } else if (hasTeacherSubsctiption) {
      window.open(
        'https://tuvalabs.com/docviewer/?title=Create%20Activity&embed_url=https://docs.google.com/document/d/1_aF7peaKyGIiGvL6ZRoW9wZX1AcpUrLWJrxeoOal3Xo/edit?embedded=true',
        '_blank',
      );
    } else {
      //basic user
      setShowPremiumBlock(true);
    }
  };

  return (
    <>
      <Button
        className={`${className} btn btn-primary`}
        onClick={e => createActivityClick()}
        target="_blank"
      >
        Create Activity
      </Button>
      {showPremiumBlock && (
        <PremiumBlockModal
          type={'Feature'}
          message={
            'Create your own custom activity around this dataset with a Tuva Subscription.'
          }
          isTuvaJrApp={is_jr_dataset}
          onClose={() => {
            setShowPremiumBlock(false);
          }}
        />
      )}
    </>
  );
};

CreateActivityButton.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.string,
    is_premium: PropTypes.bool,
  }),
  access_url: PropTypes.string,
  is_premium_dataset: PropTypes.bool,
  is_jr_dataset: PropTypes.bool,
};

export default CreateActivityButton;
