import axios from 'axios';
import { RestService } from '@tuva-ui/components';
let contentData;

class ContentOverviewAPI extends RestService {
  getContent = async (lessonId, type = 'lessons') => {
    if (contentData) {
      return contentData;
    }
    return await axios({
      method: 'get',
      url: `/api/${type}/${lessonId}/overview/`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => {
        contentData = response.data;
        return response.data;
      })
      .catch(error => ({ error }));
  };
}

const ContentOverviewService = new ContentOverviewAPI();

export default ContentOverviewService;
