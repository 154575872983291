import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
} from 'react';
import { EditorState } from '@codemirror/state';
import {
  EditorView,
  highlightSpecialChars,
  drawSelection,
  keymap,
} from '@codemirror/view';
import { defaultKeymap } from '@codemirror/commands';
import { autocompletion, startCompletion } from '@codemirror/autocomplete';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import './CodeMirror.scss';
import { BrowserSpellCheck } from './BrowserSpellCheck';
import { MARKDOWN_AUTOCOMPLETE_TYPES } from '../Constants';

let ACTIVE_AUTO_COMPLETION_TYPE_GLOBAL = MARKDOWN_AUTOCOMPLETE_TYPES.ATTRIBUTES;

const CodeMirror = React.forwardRef(
  (
    {
      initialValue = '',
      editorViewRef: editorViewRefProp,
      onChange,
      autocompletionList,
    },
    ref,
  ) => {
    const editorViewRefInternal = useRef();
    const containerRef = useRef();
    const editorViewRef = editorViewRefProp || editorViewRefInternal;

    const triggerAutoComplete = useCallback(
      type => {
        const from = editorViewRef.current.state.selection.ranges[0].from;
        // If a cursor position is greater than one, then read the current cursor charecter
        // and add space if required to show autocomplete
        if (from > 1) {
          const currentChar = editorViewRef.current.state.sliceDoc(
            from - 1,
            from,
          );
          // Add space if a current charecter is space or new line
          if (!(currentChar === ' ' || currentChar === '\n')) {
            let transaction = editorViewRef.current.state.update({
              changes: { from: from, insert: ' ' },
            });
            editorViewRef.current.dispatch(transaction);
            editorViewRef.current.dispatch({
              selection: { anchor: from + 1 },
            });
          }
        }
        // Show auto completion
        startCompletion(editorViewRef.current);
      },
      [editorViewRef],
    );

    useImperativeHandle(ref, () => ({
      getValue: () => editorViewRef.current.state.doc.toString(),
      getPosition: () => editorViewRef.current.state.selection.ranges,
      getState: () => editorViewRef.current.state,
      getView: () => editorViewRef.current,
      showAutocomplete: type => {
        editorViewRef.current.focus();
        ACTIVE_AUTO_COMPLETION_TYPE_GLOBAL = type;
        triggerAutoComplete();
      },

      // Hack for Safari rendering issue
      // https://github.com/TuvaLabs/tuva-ui/issues/1515
      // This should be cleaned if the future version of Safari resolves the rendering issue
      forceReRenderForSafari: () => {
        const editor = editorViewRef.current;
        const text = editor.state.doc.toString();
        editor.dispatch({
          changes: {
            from: 0,
            to: editor.state.doc.length,
            insert: text + ' ',
          },
        });
        setTimeout(() => {
          editor.dispatch({
            changes: {
              from: 0,
              to: editor.state.doc.length,
              insert: text,
            },
          });
        });
      },
    }));

    useEffect(() => {
      const updateListener = EditorView.updateListener.of(view => {
        if (view.docChanged) {
          if (typeof onChange === 'function') {
            onChange();
          }
        }
      });

      if (containerRef.current) {
        if (!editorViewRef.current) {
          const extensions = [
            EditorState.allowMultipleSelections.of(true),
            EditorView.lineWrapping,
            updateListener,
            highlightSpecialChars(),
            drawSelection(),
            autocompletion({
              activateOnTyping: false, //will prompt on typing anywhere in editor if true
              maxRenderedOptions: 999,
              override: [
                context => {
                  let word = context.matchBefore(/\w*/);
                  if (word.from === word.to && !context.explicit) {
                    return null;
                  }
                  return {
                    from: word.from,
                    options:
                      autocompletionList[ACTIVE_AUTO_COMPLETION_TYPE_GLOBAL],
                  };
                },
              ],
              slectOnOpen: false, // first option is not selected by default
              icons: false, // hide default icons
              addToOptions: [
                {
                  render: function (completion, state) {
                    let className = '';
                    switch (completion.type) {
                      case MARKDOWN_AUTOCOMPLETE_TYPES.ICONS:
                        className = 'ti ti-icon';
                        break;
                      case MARKDOWN_AUTOCOMPLETE_TYPES.ATTRIBUTES:
                        className = 'ti ti-attributes';
                        break;
                      case MARKDOWN_AUTOCOMPLETE_TYPES.ALERTICONS:
                        className = 'ti ti-alert-stop';
                        break;
                      default:
                        break;
                    }
                    if (className) {
                      const i = document.createElement('i');
                      i.className = className + ' mr-1';
                      return i;
                    } else {
                      return null;
                    }
                  },
                  position: 20, // default icons have position 20, the label position 50, and the detail position 70.
                },
              ],
            }),
            markdown({ base: markdownLanguage, codeLanguages: languages }),
            BrowserSpellCheck(),
            keymap.of(
              defaultKeymap.concat([
                {
                  key: 'Alt-a',
                  mac: 'Ctrl-c',
                  run: () => {
                    ACTIVE_AUTO_COMPLETION_TYPE_GLOBAL =
                      MARKDOWN_AUTOCOMPLETE_TYPES.ATTRIBUTES;
                    triggerAutoComplete();
                  },
                },
                {
                  key: 'Alt-i',
                  mac: 'Ctrl-i',
                  run: () => {
                    ACTIVE_AUTO_COMPLETION_TYPE_GLOBAL =
                      MARKDOWN_AUTOCOMPLETE_TYPES.ICONS;
                    triggerAutoComplete();
                  },
                },
              ]),
            ),
          ];

          editorViewRef.current = new EditorView({
            state: EditorState.create({
              doc: initialValue,
              extensions,
              lineNumbers: false,
            }),
            parent: containerRef.current,
          });
        }
      }
    }, [
      containerRef,
      initialValue,
      editorViewRef,
      onChange,
      autocompletionList,
      triggerAutoComplete,
    ]);

    return <div ref={containerRef} />;
  },
);

export default CodeMirror;
