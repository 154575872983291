import {
  Tuvatoolsbuttons,
  tuvaAlertButtons,
  TuvaJuniortoolsbuttons,
} from './Tuvatoolsbuttons';
import { MARKDOWN_AUTOCOMPLETE_TYPES } from '../Constants';

export let formatTuvaIcon = value => {
  return ':ti[' + value + ']';
};

export let flattern = (options, level) => {
  let _options = [];
  let prefix = '';

  for (let index = 0; index < level; index++) {
    prefix += '--';
  }
  options.forEach(function (option, index) {
    // Add line break for dropwdown menus when option having empty value
    if (option.value === '') {
      let _option = {
        label: '----------------------------------',
        type: 'heading',
        apply: () => {}, // does nothing on selection
      };
      _options.push(_option);
    } else {
      let _option = {
        label: (prefix ? prefix + ' ' : '') + option.display,
        type: MARKDOWN_AUTOCOMPLETE_TYPES.ICONS,
        apply: formatTuvaIcon(option.value),
      };
      _options.push(_option);
      if (option.children) {
        _options = _options.concat(flattern(option.children, level + 1));
      }
    }
  });
  return _options;
};

export let getDatasetAttributes = attributes => {
  let _options = [];
  const length = attributes.length;
  attributes.forEach(function (attribute, index) {
    let _option = {
      label: attribute,
      type: MARKDOWN_AUTOCOMPLETE_TYPES.ATTRIBUTES,
      apply: ':ta["' + attribute + '"]',
      boost: length - index,
    };
    _options.push(_option);
  });
  return _options;
};

export let getAlertIcons = (options, level) => {
  let _options = [];
  let prefix = '';

  for (let index = 0; index < level; index++) {
    prefix += '--';
  }
  options.forEach(function (option) {
    let _option = {
      label: (prefix ? prefix + ' ' : '') + option.display,
      type: MARKDOWN_AUTOCOMPLETE_TYPES.ICONS,
      apply: `[---\nMESSAGE_TEXT\n---] {${option.value}}`,
    };
    _options.push(_option);
    if (option.children) {
      _options = _options.concat(flattern(option.children, level + 1));
    }
  });
  return _options;
};

const MarkdownLookupUtil = {
  populateAttributesAndTuvatoolsButtons(datasetAttributes, isTuvaJrDataset) {
    let autocompleteList = [];
    let attributes = getDatasetAttributes(datasetAttributes);
    let toolsButtons = flattern(Tuvatoolsbuttons, 0);
    let alertButtons = getAlertIcons(tuvaAlertButtons, 0);
    if (isTuvaJrDataset) {
      toolsButtons = flattern(TuvaJuniortoolsbuttons, 0);
    }
    let length = toolsButtons.length;
    // To maintain order
    toolsButtons.forEach(function (btn, index) {
      btn.boost = length - index;
    });
    autocompleteList[MARKDOWN_AUTOCOMPLETE_TYPES.ATTRIBUTES] = attributes;
    autocompleteList[MARKDOWN_AUTOCOMPLETE_TYPES.ICONS] = toolsButtons;
    autocompleteList[MARKDOWN_AUTOCOMPLETE_TYPES.ALERTICONS] = alertButtons;
    return autocompleteList;
  },
};

export default MarkdownLookupUtil;
