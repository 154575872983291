import axios from 'axios';
import { RestService } from '@tuva-ui/components';

class BookmarkAPI extends RestService {
  addBookmark = (id, object_type) => {
    return axios({
      method: 'post',
      url: `/api/bookmark/`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        object_type,
        object_id: id,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  };

  removeBookmark = bookmarkId => {
    return axios({
      method: 'delete',
      url: `/api/bookmark/${bookmarkId}`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  };
}
const BookmarkService = new BookmarkAPI();

export default BookmarkService;
