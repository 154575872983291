import React from 'react';
import PropTypes from 'prop-types';

function Author({ username, name, isStaff, isDoctrina, className }) {
  return username ? (
    <span className={className}>
      <i className="ti ti-auther mr-2" />
      <a
        className="p3 link-regular-9"
        target="_blank"
        href={`/profile/${username}/`}
        rel="noreferrer"
      >
        {name} {isStaff ? (isDoctrina ? ' (By Staff)' : ' (By Tuva Staff)') : ''}
      </a>
    </span>
  ) : (
    <span className="p3 text-gray m-0">
      <i className="ti ti-auther p3 text-gray lh-condensed-ultra mr-2" />
      {isDoctrina ? 'By Staff' : 'By Tuva Staff'}
    </span>
  );
}

Author.propTypes = {
  username: PropTypes.string,
  name: PropTypes.string,
  isStaff: PropTypes.bool,
  isDoctrina: PropTypes.bool,
  className: PropTypes.string,
};

Author.defaultProps = {
  className: '',
};

export default Author;
