import React, { Component } from 'react';
import { Markdown, QuestionsUtil } from '@tuva-ui/components/';

/**
 * @author Naveenkumar Ganesan
 */
class MarkdownWrapper extends Component {
  state = {
    answers: {},
  };

  // Set user answers when it is not null
  componentDidMount() {
    if (this.props.userAnswers) {
      this.setState({
        answers: this.props.userAnswers,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Give priority to user answers if it is changed from null
    if (
      prevProps.content !== this.props.content ||
      prevProps.userAnswers != this.props.userAnswers
    ) {
      this.setState({
        answers: this.props.userAnswers,
      });
    }
  }

  getAnswers() {
    return this.state.answers;
  }

  setAnswer = answers => {
    this.setState({
      answers,
    });
  };

  isValid = () => {
    return this.markdownEle?.isValid();
  };

  hasCheckableAns() {
    if (this.props.questions) {
      return QuestionsUtil.hasCheckableAns(this.props.questions);
    }
  }

  render() {
    return (
      <Markdown
        ref={ele => (this.markdownEle = ele)}
        showAnswer={this.props.checkIsFormDisabled()}
        isDisabled={this.props.checkIsFormDisabled()}
        answers={this.state.answers}
        value={this.props.content}
        questions={this.props.questions}
        answerKey={this.props.answerKey}
        setAnswer={this.setAnswer}
        getPlotState={this.props.getPlotState}
        getPlotPreview={this.props.getPlotPreview}
        onIconClick={this.props.onIconClick}
        onSimulation={this.props.onSimulation}
      />
    );
  }
}

export default MarkdownWrapper;
