import React from 'react';
import PropTypes from 'prop-types';

const AnchorWithoutLink = ({
  onClick = null,
  className = '',
  style = {},
  children,
  title,
}) => {
  return (
    <a
      role="button"
      title={title}
      rel="noreferrer"
      href={'#!'}
      className={(onClick ? 'cursor-pointer ' : '') + className}
      style={style}
      onClick={e => {
        // Prevent default browser event for anchor tag without link
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </a>
  );
};

AnchorWithoutLink.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default AnchorWithoutLink;
