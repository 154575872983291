import axios from 'axios';
var source;
class ActivityService {
  getActivity(id) {
    return axios.get(`/api/activity/${id}`).then(response => {
      return response.data;
    });
  }

  copyActivity(datasetSlug, activityId) {
    return axios
      .post(`/datasets/${datasetSlug}/copy/${activityId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }
  getTeacherActivities(districtSlug, nextUrl) {
    if (source) {
      source.cancel();
    }
    const CancelToken = axios.CancelToken;
    source = CancelToken.source();
    return axios({
      cancelToken: source.token,
      method: 'get',
      url: !!nextUrl
        ? nextUrl
        : `/api/analytics2/district/${districtSlug}/teachers-content/`,
      headers: { 'Content-Type': 'application/json' },
      params: !!nextUrl
        ? {}
        : {
            view: 'block',
            filter_type: 'bookmarks',
          },
    })
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
}

const ActivityAPI = new ActivityService();

export default ActivityAPI;
