import React, { useState } from 'react';
import {
  MarkdownInline,
  TuvaToast,
  Author,
  Util,
  BookmarkService,
} from '@tuva-ui/components/';
import ContentImg from './atoms/ContentImg';
import ContentType from './atoms/ContentType';
import BookmarkButton from './atoms/BookmarkButton';
import TeacherGuide from './atoms/TeacherGuide';
import CaseCount from './atoms/CaseCount';
import Attributes from './atoms/Attributes';
// import ContentSubType from './atoms/ContentSubType';
import Steps from './atoms/Steps';
import Tags from './atoms/Tags';
import CreateActivityButton from './atoms/CreateActivityButton';
import CollectionsDefaultIcon from '../static/img/collections-default-img.png';
import './MiniBlock.scss';
const showLoginForm = window.showLoginForm;

const ContentBlockV2 = ({
  data,
  user,
  bookmarkCallback,
  isSubdomain,
  isBookmarkType,
  isDoctrina,
  isFromContentLibrary,
  show,
  iconOnlyMode,
  isTuvaJrApp,
  width,
  activeContentTypes,
  isSlickSliderView,
}) => {
  let {
    lesson_type,
    object_type,
    get_image,
    is_premium,
    access_url,
    created_by_staff,
  } = data;
  let title = '';
  let description = '';
  let contentTypeIcon = null;
  let contentTypeLabel = Util.getContentTypeName(
    lesson_type || object_type,
    data.is_tuva_jr,
  ); // object_type for activity
  let author = '';
  let createdBy = 'Tuva Staff';
  let activityType = [];
  let datastoryType = [];
  let isJunior = !!user ? user.is_jr : false;
  let noOfSteps = 0;
  let datasetNoOfActivities = 0;
  let tags = [];
  let objectId = 0;
  let isPremium = !!user ? user.is_premium : false;
  let refDocCount = 0;
  let refDoc = '';
  let isNew = !!data.is_new ? true : false;
  let isArchived = !!data.is_archived ? true : false;
  let isRevised = !!data.is_revised ? true : false;
  let hasAnswerkey = !!data.has_answerkey ? true : false;
  let isCollectionBlock = false;
  let isDatset = object_type === 'dataset';
  let isCourse = object_type === 'course';
  let showCreateActivity =
    show !== 'archived' &&
    isDatset &&
    !isTuvaJrApp &&
    !isSubdomain &&
    (!user || (user && user.type === 'teacher'));

  const [bookmarkId, setBookmarkId] = useState(
    !!data.bookmark_id ? data.bookmark_id : 0,
  );

  const [isBookmarking, setisBookmarking] = useState(false);
  get_image = get_image === 'None' ? undefined : get_image;
  if (isDatset) {
    title = data.dataset_name;
    description = data.case_desc;
    contentTypeIcon = 'ti-dataset';
    datasetNoOfActivities = data.no_of_activities;
    access_url = `/${isSubdomain ? 'tuvadatasets' : 'datasets'}/${access_url}/`;
    refDocCount = data.ref_doc_count;
    refDoc = data.ref_doc;
  } else if (object_type === 'activity') {
    title = data.activity_title;
    description = data.description;
    contentTypeIcon = 'ti-activities';
    createdBy = data.created_by;
    author = data.author;
    activityType = data.activity_type;
    noOfSteps = data.no_of_steps;
  } else {
    title = data.name;
    description = data.description;
  }

  if (object_type === 'lesson') {
    createdBy = data.created_by;
    author = data.author;
    contentTypeIcon = 'ti-datastories';
    datastoryType = data.inst_strategy_type;
    noOfSteps = data.no_of_steps;
  }

  if (object_type === 'course') {
    contentTypeIcon = 'ti-course';
    access_url = `/courses/${access_url}`;
  }

  if (object_type === 'taskassessment') {
    createdBy = data.created_by;
    author = data.author;
    contentTypeIcon = 'ti-assessments';
    datastoryType = data.inst_strategy_type;
    noOfSteps = data.no_of_steps;
  }

  if (!!object_type) {
    tags = data.math_tags;
    if (isTuvaJrApp || isCourse) {
      tags = data.tags;
    }
    objectId = data.object_id;
  } else {
    contentTypeIcon = 'ti-collections';
    contentTypeLabel = data.collections_count;
    access_url = `/collections/${access_url}`;
    if (isFromContentLibrary) {
      isCollectionBlock = true;
    }
  }

  const toggleBookmark = (objectId, object_type) => {
    if (user) {
      setisBookmarking(true);
      if (!!bookmarkId) {
        BookmarkService.removeBookmark(bookmarkId).then(data => {
          TuvaToast.info('Removed Bookmark.');
          setBookmarkId(0);
          bookmarkCallback(-1);
          setisBookmarking(false);
        });
      } else {
        let objType =
          object_type === 'activity'
            ? 'datasetactivity'
            : object_type === 'datastory'
            ? 'lesson'
            : object_type === 'course'
            ? 'course'
            : object_type;
        BookmarkService.addBookmark(objectId, objType).then(data => {
          if (data?.id) {
            TuvaToast.info('Bookmarked!');
            setBookmarkId(data.id);
            bookmarkCallback(+1);
            setisBookmarking(false);
          } else {
            TuvaToast.error('We encountered an issue. Please try again later.');
            setisBookmarking(false);
          }
        });
      }
    } else {
      showLoginForm();
    }
  };

  if (isCollectionBlock && (!get_image || get_image === 'None')) {
    get_image = CollectionsDefaultIcon;
  }

  return !isBookmarkType || (isBookmarkType && bookmarkId !== 0) ? (
    <div
      style={{ maxWidth: width ? width : '350px', minHeight: '430px' }}
      className="bg-white flex-column border border-gray-300 content-block d-flex m-1 pb-3 pb-md-0"
    >
      <div className="content-block_info content-block_info_v2 flex-1">
        <div className="content-block_type d-flex flex-justify-between px-2 pt-2">
          <ContentType
            iconOnlyMode={iconOnlyMode}
            isFromMathLib={true}
            contentTypeLabel={contentTypeLabel}
            contentTypeIcon={contentTypeIcon}
            is_premium={is_premium}
            isUserPremium={isPremium}
            isUserJunior={isJunior}
            isDoctrina={isDoctrina}
            user={user}
            hasAnswerkey={hasAnswerkey}
            isCollectionBlock={isCollectionBlock}
            isTuvaJrApp={isTuvaJrApp}
          />
          <div className="d-flex">
            {showCreateActivity && (
              <CreateActivityButton
                user={user}
                access_url={data.access_url}
                is_premium_dataset={data.is_premium}
                is_jr_dataset={data.is_tuva_jr}
              />
            )}

            {(object_type === 'lesson' ||
              object_type === 'activity' ||
              object_type === 'course' ||
              object_type === 'dataset') &&
              !isSubdomain && (
                <div className={'ml-2'}>
                  <BookmarkButton
                    isFromMathLib={true}
                    bookmarkId={bookmarkId}
                    isBookmarking={isBookmarking}
                    toggleBookmark={() => toggleBookmark(objectId, object_type)}
                  />
                </div>
              )}
          </div>
        </div>
        <ContentImg
          className={`content-block_image-v2 mt-2 width-full ${
            isSlickSliderView ? '' : 'img-min-width'
          }`}
          access_url={access_url}
          title={title}
          get_image={get_image}
          isNew={isNew}
          isArchived={isArchived}
          isRevised={isRevised}
          isFromMathLib={true}
        />
        <div className="content-block_details px-3">
          <a target="_self" href={access_url} title="">
            <h3 className="font-weight-semibold p0 text-green mt-2 lh-default overflow-hidden">
              <MarkdownInline
                className=""
                value={title}
                isDisabled={false}
              ></MarkdownInline>
            </h3>
          </a>
          <p className="p2 text-gray description lh-default overflow-hidden">
            <MarkdownInline
              className=""
              value={description}
              isDisabled={false}
            ></MarkdownInline>
          </p>

          <div className="tuva-source">
            {isDatset && data.datasource?.length > 0 && (
              <div className="mt-1 p2 text-gray lh-default ellipsis overflow-hidden">
                Source:{' '}
                <MarkdownInline
                  className=""
                  value={data.datasource[0]}
                  isDisabled={false}
                ></MarkdownInline>
              </div>
            )}
          </div>
          <div className="content-block_extrainfo d-flex mt-3">
            {isDatset ? (
              data.casecount > 0 && (
                <CaseCount isFromMiniBlock={true} count={data.casecount} />
              )
            ) : (
              <Author
                username={author}
                name={createdBy}
                isStaff={created_by_staff}
                isDoctrina={isDoctrina}
                className={'content_auther mr-4 d-flex flex-items-center'}
              />
            )}

            {isDatset && data.no_of_attrib > 0 && (
              <Attributes isFromMiniBlock={true} count={data.no_of_attrib} />
            )}

            {object_type === 'dataset' && !isTuvaJrApp && refDocCount > 0 && (
              <TeacherGuide
                is_premium={is_premium}
                isUserPremium={isPremium}
                refDoc={refDoc}
              />
            )}

            {/* {object_type === 'activity' && !!activityType.length && (
              <ContentSubType listOfTags={activityType} />
            )}
            {object_type === 'lesson' && !!datastoryType.length && (
              <ContentSubType listOfTags={datastoryType} />
            )} */}

            <div style={{ marginLeft: 'auto' }}>
              <Steps
                object_type={object_type}
                datasetNoOfActivities={datasetNoOfActivities}
                noOfSteps={noOfSteps}
                isFromMiniBlock={true}
              />
            </div>
          </div>
          <Tags
            activeContentTypes={activeContentTypes}
            tags={tags}
            isTuvaJrApp={isTuvaJrApp}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default ContentBlockV2;
